import { PeraWalletConnect } from "@perawallet/connect";
import * as $ from "jquery";
import algosdk from "algosdk";

const peraWallet = new PeraWalletConnect();
console.log("peraWallet", peraWallet);
const indexer_token = {
  "X-API-Key": "mGWBL05ZyLHqY44csPKT6gxxTZsHHAB1s1VpOekf",
};

const CLIENT_SERVER_URL = "https://mainnet-algorand.api.purestake.io/ps2";
const CLIENT_SERVER_PORT = "";

const algodclient = new algosdk.Algodv2(
  indexer_token,
  CLIENT_SERVER_URL,
  CLIENT_SERVER_PORT
);

const somefunction = async () => {
  let algodclient = new algosdk.Algodv2(
    indexer_token,
    CLIENT_SERVER_URL,
    CLIENT_SERVER_PORT
  );

  let note = "Buy token Optin";
  let assetID = parseInt("137020565");

  let txn = await algodclient.getTransactionParams().do();

  txn = {
    ...txn,
    fee: 1000,
    flatFee: true,
    type: "axfer",
    amount: parseFloat($("#wallet_add_amount").val()) * 100,
    note: note,
  };
  console.log("txn1:->", txn);
};

async function generateAssetTransferTxns({ to, assetID, initiatorAddr }) {
  const suggestedParams = await algodclient.getTransactionParams().do();
  const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: initiatorAddr,
    to: to,
    assetIndex: assetID,
    amount: parseFloat($("#wallet_add_amount").val()) * 100,
    suggestedParams,
  });
  console.log("txn2:->", txn);
  return [{ txn, signers: [initiatorAddr] }];
}

let accountAddress = "";

$("#pera").on("click", function (event) {
  if (accountAddress) {
    handleDisconnectWalletClick(event);
  } else {
    handleConnectWalletClick(event);
  }

  function handleConnectWalletClick(event) {
    event.preventDefault();
    $.noConflict();

    peraWallet
      .connect()
      .then((newAccounts) => {
        $("#wallet_type").val(1);
        peraWallet.connector.on("disconnect", handleDisconnectWalletClick);
        accountAddress = newAccounts[0];
        const shortenedAddress =
          accountAddress.slice(0, 10) +
          "**********" +
          accountAddress.slice(-10);
        console.log(shortenedAddress);
        var wallet_address = document
          .getElementById("wallet-add")
          .append("Wallet#:", shortenedAddress);
        const address = accountAddress;
        console.log("address", address);
        var element = document.getElementById("trnbtn");

        var wallet_address = element.setAttribute(
          "data-address",
          accountAddress
        );
        $("#detail").show();
        var k = $(".fundtransfer").attr("data-address");
        gtData();
        function gtData() {
          $.ajax({
            url: "https://stacking.iosandweb.net/gtapp.php",
            //    url: "http://localhost/gtapp.php",
            type: "POST",
            dataType: "json",

            data: {
              from: k,
            },
            success: (result) => {
              var len = result.length;
              console.log("len:->", len);
              for (var i = 0; i < len; i++) {
                var from = result[i].frm;
                var to = result[i].too;
                var amt = result[i].amt;
                var tstamp = result[i].tmestamp;

                console.log("to,amt", to, amt);
                const shortened = to.slice(0, 6) + "**********" + to.slice(-6);
                console.log(shortened);
                const shortened2 =
                  from.slice(0, 6) + "**********" + from.slice(-6);
                var row = $(
                  '<tr><th scope="row">' +
                    shortened2 +
                    "</th><td>" +
                    shortened +
                    '</td><td style="text-align: center;">' +
                    result[i].amt +
                    "</td><td>" +
                    result[i].tmestamp +
                    "</td> </tr>"
                );
                $("#trabody").append(row);
              }
            },
            error: (err) => {
              console.log(err);
              console.error("Error fetching data: " + err);
            },
          });
        }
        const assetId = 137020565;
        var a = algodclient
          .accountInformation(address)
          .do()
          .then((accountInfo) => {
            const assetHolding = accountInfo.assets.find(
              (asset) => asset["asset-id"] === assetId
            );
            console.log("assetHolding", assetHolding.amount);
            if (assetHolding.amount == 0) {
              alert(`Account does not hold ASA BUY TOKENS`);
            } else {
              var asset_amount = $("#coin_balance").html(
                '<img src="https://buying.com/catalog/view/theme/default/image/wallet/small-bitcoin.png"> ' +
                  assetHolding.amount / 100 +
                  " BUY"
              );
            }
          });

        var algotoken = algodclient
          .accountInformation(address)
          .do()
          .then((accountInfo) => {
            console.log("accountInfo:->", accountInfo);
            if (accountInfo.amount == 0) {
              console.log("Not enough Algo Token");
            } else {
              var asset_amount = $("#algo_balance").html(
                '<img src="https://buying.com/catalog/view/theme/default/image/wallet/algos.png"> ' +
                  accountInfo.amount / 1000000 +
                  " ALGO"
              );
            }
          });

        window.$("#myModal").modal("hide");
        var b = algodclient
          .accountInformation(address)
          .do()
          .then((accountInfo) => {
            const assetHolding = accountInfo.assets.find(
              (asset) => asset["asset-id"] === assetId
            );
            if (assetHolding) {
              var asset_amount = document
                .getElementById("balance")
                .append("Balance:", assetHolding.amount / 100, " BUY TOKENS");
            } else {
              console.log(`Account does not hold ASA ${assetId}`);
            }
          });
      })
      .catch((error) => {
        if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
          console.log(error);
        }
      });
  }

  function handleDisconnectWalletClick(event) {
    event.preventDefault();

    peraWallet.disconnect().catch((error) => {
      console.log(error);
    });

    accountAddress = "";
    connectButton.innerHTML = "Connect to Wallet";
  }
});

$("#trnbtn").on("click", function (e) {
  var u = $(".fundtransfer").attr("data-address");
  console.log(u.length);
  if (u.length == 0) {
    window.$("#myModal").modal("show");
  } else {
    window.$("#myModal").modal("hide");
    window.$("#secondModal").modal("show");
  }
});

$("#trnbtn3").on("click", function (e) {
  var o = $("#wallet_type").val();
  console.log(o);
  if (o == 1) {
    var u = $(".fundtransfer").attr("data-address");
    var v = $("#wallet_add_algo").val();
    var z = parseFloat($("#wallet_add_amount").val());
    var g = parseFloat($("#wallet_add_amount").val()) * 100;
    if (v == "") {
      $("#accef").text("Please Enter Address");
    } else if (v.length < 58) {
      $("#accef").text("Please Enter Valid Address");
    } else {
      $("#accef").text("");
    }
    const assetID = 137020565;
    console.log("assetID", assetID);
    var b = algodclient
      .accountInformation(u)
      .do()
      .then((accountInfo) => {
        const assetHolding = accountInfo.assets.find(
          (asset) => asset["asset-id"] === assetID
        );
        console.log("assetHolding:->", assetHolding);
        if (assetHolding.amount < g) {
          $("#insufficient").text("Your Account has insufficient Balance");
        } else if (isNaN(g)) {
          $("#insufficient").text("Please Enter a Value");
        } else if (g <= 0) {
          $("#insufficient").text("Please Enter a Valid Amount");
        } else {
          $("#insufficient").text("");
          (async () => {
            let note = "Buy token Optin";
            let assetID = parseInt("137020565");

            try {
              const txGroups = await generateAssetTransferTxns({
                to: v,
                assetID: 137020565,
                initiatorAddr: u,
              });
              console.log("txGroups:=>", txGroups);
              try {
                const signedTxnGroup = await peraWallet.signTransaction([
                  txGroups,
                ]);
                console.log(signedTxnGroup);

                const { txId } = await algodclient
                  .sendRawTransaction(signedTxnGroup)
                  .do();
                console.log("txId", { txId });
                if ({ txId } === null) {
                  document
                    .getElementById("tyoumessage")
                    .append("Your Transaction has failed");
                } else {
                  document
                    .getElementById("tyoumessage")
                    .append("Your Transaction has been done successfully");

                  setTimeout(function () {
                    window.$("#secondModal").modal("hide");
                  }, 2000);

                  setTimeout(function () {
                    $(".modal-backdrop.show").attr(
                      "style",
                      "opacity: 0 !important"
                    );
                  }, 2000);

                  enterTxnData();
                  function enterTxnData() {
                    $.ajax({
                      url: "https://stacking.iosandweb.net/app.php",
                      //      url: "http://localhost/app.php",
                      type: "POST",
                      contentType: "application/json",
                      dataType: "json",
                      data: JSON.stringify({
                        from: u,
                        to: v,
                        amt: z,
                        timestamp: new Date().toLocaleString(),
                      }),
                      async: true,
                      success: (response) => {
                        if (response.status === "success") {
                          var data = response.data;
                          const short2 =
                            data.from.slice(0, 6) +
                            "**********" +
                            data.from.slice(-6);
                          const short =
                            data.to.slice(0, 6) +
                            "**********" +
                            data.to.slice(-6);
                          $("#trabody").append(
                            '<tr><th scope="row">' +
                              short2 +
                              "</th><td>" +
                              short +
                              '</td><td style="text-align: center;">' +
                              data.amount +
                              "</td><td>" +
                              data.timestamp +
                              "</td></tr>"
                          );
                        } else {
                          console.error(
                            "Error adding data: " + response.message
                          );
                        }
                      },
                      error: (err) => {
                        console.error("Error adding data: " + err);
                      },
                    });
                  }
                }
              } catch (error) {
                console.log("Couldn't sign asset transfer txns", error);
              }
            } catch (error) {
              console.log("asdasd");
              console.log(error);
            }
          })();
        }
      });
  } else {
    console.log("You can Switch Your Wallet");
  }
});
